import Head from 'next/head';
import { useRouter } from 'next/router';
import { VStack } from '@neui/layout';
import { Headline } from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';
import React, { useRef, useState } from 'react';
import { Section } from '@components/neui-components/atoms/Section';
import { $t } from '@utils/i18n';
import { createPortalEntity } from '@utils/snowplowTracking';
import { ChatEntryPoint } from '@components/Chat/ChatEntryPoint';
import { selectChatProps, useChatStore } from '@components/Chat/store/store';
import { GA4SearchType } from '@utils/tracking';
import {
  getSearchParameters,
  getUrlParameter,
} from '@components/Search/helpers';
import { CdsSearch } from '@components/Search/CdsSearch';
import { SuggestionResultType } from '@components/Search';
import { Categories } from '@components/Categories/Categories';
import { SelfServiceIconGroup } from '@components/SelfServiceIconGroup/SelfServiceIconGroup';
import { OBServices } from '@components/OBServices/OBServices';

import { CdsIndexPageProps } from '../pages/index';
import { XColumnsGridItem } from './XColumnsGridItem';
import {
  checkNachKon,
  checkNachKonDE,
  getNachKonMostSearchedTerms,
} from '@utils/nachkonVariables';

export const OBIndexPage = ({
  topCategories,
  metadata,
  mostSearchedTerms,
}: CdsIndexPageProps) => {
  const portalContext = createPortalEntity('lp', OBIndexPage.name);

  const router = useRouter();

  const [, setSuggestions] = useState<SuggestionResultType[]>([]);

  const { chatState } = useChatStore(selectChatProps);

  const resetQuery = useRef<(query: string) => void>();

  const [, setIsSearchFocused] = useState(false);

  const { searchUrl } = metadata;

  const itm = router.pathname.includes('/search')
    ? getUrlParameter(router, 'itm')
    : undefined;

  const isNachKon = checkNachKon(itm);
  const isNachKonDE = checkNachKonDE(itm);

  const searchApiUrl = !isNachKon
    ? searchUrl
    : isNachKonDE
      ? process.env.NEXT_PUBLIC_NACHKON_SEARCH_URL_DE ?? ''
      : process.env.NEXT_PUBLIC_NACHKON_SEARCH_URL_EN ?? '';

  const nachKonMostSearchedTerms = isNachKon
    ? getNachKonMostSearchedTerms(isNachKonDE)
    : undefined;

  const searchParameters = getSearchParameters(router);

  const executeSearch = async (query: string, searchType: GA4SearchType) => {
    window.location.href = `${router.basePath}/?q=${query}&searchType=${searchType}`;
  };
  return (
    <>
      <Head>
        <meta name="de-comdirect-provider-template" content="layout_5_0" />
      </Head>
      <Section css={{ '& > div': { gap: '$layout-2' } }}>
        <Headline type={'h3'} renderAs="h1">
          {$t('SEARCH_HEADLINE')}
        </Headline>
        <VStack spacing={'$subsection'}>
          <SearchContainer>
            <CdsSearch
              searchUrl={searchApiUrl}
              initialQuery={searchParameters.q}
              executeSearch={executeSearch}
              onFocusChange={setIsSearchFocused}
              onSuggestionsChange={setSuggestions}
              setResetQuery={(f) => (resetQuery.current = f)}
              isDE={true}
              variant="base"
              compactLayout={true}
              mostSearchedTerms={nachKonMostSearchedTerms ?? mostSearchedTerms}
            />
          </SearchContainer>
          <SelfServiceIconGroup
            metadata={metadata}
            isOB={true}
            sectionNumber={1}
            sectionTotal={3}
          />
        </VStack>
      </Section>
      {chatState.workgroupName && (
        <StyledSection>
          <XColumnsGridItem columns={{ base: 12, lg: 8 }}>
            <ChatEntryPoint
              headline={'Wir sind für Sie da'}
              text={
                'Persönlich und rund um die Uhr. Im authentifizierten Kundencenter können wir nicht nur Ihre Fragen beantworten, sondern auch viele weitere Leistungen anbieten. Vom Girokonto bis zur neuen Kreditkarte.'
              }
              portalContext={portalContext}
            />
          </XColumnsGridItem>
        </StyledSection>
      )}
      <OBServices metadata={metadata} />
      <Categories
        topCategories={topCategories}
        metadata={metadata}
        sectionNumber={3}
        sectionTotal={3}
      />
    </>
  );
};

export const StyledSection = styled(Section, {
  paddingTop: '0 !important',
});

const SearchContainer = styled(VStack, {
  position: 'relative',
  width: '100%',
  maxWidth: '792px',
  height: '66px',
  marginBottom: '$layout-2',
});
