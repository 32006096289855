import { ActionButton } from '@components/neui-components/molecules/ActionButton';
import { Text } from '@neui/styleguide-commerzbank';
import { styled } from '@neui/core';
import { makeStagedLinkFunction } from '@utils/helpers/linkHelpers';
import { iconGroupInfo as ObIconGroupInfo } from '@utils/OB-iconGroupInfo';
import {
  fkIconGroupInfo,
  cdsIconGroupInfo,
  IconGroupItem,
} from '@utils/iconGroupInfo';
import { isGpp, isGppDE, Metadata } from '@utils/pageUtils';
import { useTracker } from '@utils/snowplowTracking';
import { useMakeLink } from '@components/Link';
import { GA4TrackNavigationClick } from '@utils/tracking';
import { $t } from '@utils/i18n';
import { InView } from 'react-intersection-observer';
import { handleInViewChange } from '@utils/helpers/misc';

export type CategoriesProps = {
  metadata: Metadata;
  isOB: boolean;
  sectionNumber: number;
  sectionTotal: number;
};

export const SelfServiceIconGroup = ({
  metadata,
  isOB,
  sectionNumber,
  sectionTotal,
}: CategoriesProps) => {
  const { deploymentStage } = metadata;
  const { trackButtonClick } = useTracker(SelfServiceIconGroup.name);

  const makeStagedLink = makeStagedLinkFunction(deploymentStage);
  const makeLink = useMakeLink();

  const iconGroupData: IconGroupItem[] = isGpp
    ? fkIconGroupInfo(isGppDE)
    : isOB
      ? ObIconGroupInfo(makeStagedLink)
      : cdsIconGroupInfo(makeStagedLink);

  return (
    <InView
      onChange={handleInViewChange(
        $t('SELFSERVICE_SUBLINE'),
        sectionNumber,
        sectionTotal,
      )}
      triggerOnce={true}
    >
      {({ ref }) => (
        <>
          <Text type={'helper'} ref={ref}>
            {$t('SELFSERVICE_SUBLINE')}
          </Text>
          <StyledUl>
            {iconGroupData.map((topic, index) => (
              <StyledLi key={index}>
                <ActionButton
                  icon={topic.icon}
                  look={'secondary'}
                  href={topic.url}
                  label={topic.label}
                  onClick={() => {
                    trackButtonClick?.(topic.label, 'self_service', []);
                    !isGpp &&
                      GA4TrackNavigationClick(
                        'navigationClick',
                        isOB ? 'OB ActionButton' : 'ActionButton',
                        topic.label,
                        makeLink({ href: topic.url, absoluteUrl: true }),
                      );
                  }}
                />
              </StyledLi>
            ))}
          </StyledUl>
        </>
      )}
    </InView>
  );
};

export const StyledUl = styled('ul', {
  gridGap: '$layout-2',
  display: 'flex',
  flexWrap: 'wrap',
  '@lg': {
    gridGap: '$layout-3 $layout-2',
  },
});

export const StyledLi = styled('li', {
  listStyle: 'none',
  width: '112px',
  '@lg': {
    gap: '$layout-5',
    width: '128px',
  },
});
